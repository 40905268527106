import 'antd/dist/reset.css';
import './App.css';
import React from "react";
import { Traits, Item, Location, LocationString, Quality } from './data';
import { AutoComplete, Button, Segmented, Select, Space, Table, Tag, Input, theme, Radio, InputNumber } from 'antd';
import { _APP_HOST, calcLevel, debounce, getTraitName, timeSince } from "./lib";
import { ConfigProvider } from 'antd';
import qs from 'qs-stringify';
import { UpdateButton } from "./update-button";
import ListTable from "./list-table";
import FilterBar from "./filter-bar";


function combineLink(i) {
  return [
    i.TradeAsset.Item.ID,
    i.TradeAsset.Item.LevelTotal,
    i.TradeAsset.Item.QualityID,
    i.TradeAsset.Item.TraitID,
    i.TradeAsset.Item.Category2IDOverWrite
  ].filter((i) => Boolean(i))
    .join('-');
}

const defaultParams = () => ({
  currentPage: 1,
  orderKey: "LastSeen",
  order: "ascend",
  total: 0,
});

function App() {

  const [ dataList, setDataList ] = React.useState([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ tableParams, setTableParams ] = React.useState(defaultParams());
  const [ searchParams, setSearchParams ] = React.useState({});

  function onSearchChange(params) {
    setSearchParams(params);
  }

  async function onSearch() {
    setLoading(true);

    let Order = defaultParams().order;
    if (tableParams.orderKey === 'LastSeen') {
      Order = tableParams.order === "ascend" ? "desc" : "asc";
    } else if (tableParams.orderKey === 'Price') {
      Order = tableParams.order === "ascend" ? "asc" : "desc";
    }

    const obj = {
      page: tableParams.currentPage,
      Order: Order,
      SortBy: tableParams.orderKey,
    }

    const reqStr = qs({ ...searchParams, ...obj });
    console.log(reqStr, `<---- reqStr`);

    const rs = await fetch(`${_APP_HOST}/search?${reqStr}`).then(r => r.json())
    setLoading(false);
    if (rs.Code !== 0) return;
    const { TradeListPageModel: { TradeDetails, TotalMatchCount } } = rs;


    const reqData = TradeDetails.map(i => ({
      QualityID: i.TradeAsset.Item.QualityID,
      ID: i.TradeAsset.Item.ID,
      TraitID: i.TradeAsset.Item.TraitID !== null ? i.TradeAsset.Item.TraitID : -1,
      LevelTotal: i.TradeAsset.Item.LevelTotal
    }))

    const diffData = await fetch(`${_APP_HOST}/detect`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(reqData)
    }).then(r => r.json())

    const data = TradeDetails.map((i, idx) => ({
      name: i.TradeAsset.Item.Name,
      guild: i.GuildName,
      LastSeen: new Date(i.DiscoverUnixTime * 1000),
      location: LocationString[Location[i.GuildKioskLocationID]],
      totalPrice: i.TradeAsset.TotalPrice,
      quality: i.TradeAsset.Item.QualityID,
      icon: i.TradeAsset.Item.IconName,
      key: i.ID,
      amount: i.TradeAsset.Amount,
      Price: i.TradeAsset.UnitPrice,
      trait: getTraitName(i.TradeAsset.Item.TraitID),
      level: calcLevel(i.TradeAsset.Item.LevelTotal),
      diffPrice: diffData[idx],
      linkID: combineLink(i),
    }));

    setDataList(data);
    setTableParams({
      ...tableParams,
      total: TotalMatchCount,
    });
    setLoading(false);
  }

  React.useEffect(() => {
    console.log(tableParams, `<---- onChange tableParams`);
    onSearch()
  }, [ tableParams.currentPage, tableParams.order, tableParams.orderKey, searchParams ])

  function handleTableChange(pagination, filters, sorter) {
    console.log(pagination, `<---- pagination,filters,sorter`);
    console.log(sorter, `<---- sorter`);
    setTableParams({
      ...tableParams,
      currentPage: pagination.current,
      orderKey: sorter.columnKey,
      order: sorter.order || 'ascend',
    })
  }

  function onReset(params) {
    console.log(params, `<---- params`);
    setDataList([]);
    setSearchParams({});
    setTableParams(defaultParams())
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          // colorPrimary: '#00b96b',
          colorBgBase: '#000',
          colorTextBase: '#eee',
        },
      }}
    >
      <div className="main-body">
        <FilterBar onReset={onReset} onSearch={onSearchChange} />
        <ListTable tableParams={tableParams} dataList={dataList} loading={loading}
                   handleTableChange={handleTableChange} />
      </div>
    </ConfigProvider>
  )
}

export default App;
