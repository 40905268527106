import { Button, Modal } from "antd";
import React from "react";
import { _APP_HOST } from "./lib";

export function UpdateButton() {
  const [ loading, setLoading ] = React.useState(false);

  function onClick() {
    setLoading(true);
    fetch(`${_APP_HOST}/update`).then(r => r.json()).then(r => {
      const { code, message: msg } = r;
      if (code === 0) {
        Modal.success({
          title: 'Success',
          content: msg,
        });
      } else {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }).catch(e => {
      Modal.error({
        title: 'Error',
        content: 'Update Error!',
      });
    }).finally(() => {
      setLoading(false)
    });
  }

  return (
    <Button onClick={onClick} loading={loading}>Update Data</Button>
  )
}
