import React from "react";
import { Button, Popover, Space, Spin } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { _APP_HOST } from "./lib";

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

export default function ListButton(props) {
  const { data = {}, price = 0 } = props;
  const [ open, setOpen ] = React.useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  function calc(a, b) {
    const dValue = Number.parseFloat(a) - Number.parseFloat(b);
    const rateValue = dValue / Number.parseFloat(b)
    const rate = Math.floor(rateValue * 10000) / 100
    return { dValue: Math.floor(dValue * 100) / 100, rate }
  }

  function renderTips() {
    let dValue = '--';
    let rate = '--';
    if (data?.SuggestedPrice) {
      const obj = calc(data?.SuggestedPrice, price);
      dValue = obj.dValue;
      rate = obj.rate;
    }

    let color = '';
    if (dValue > 0) color = 'raise';
    if (dValue < 0) color = 'fall';

    return (
      <div className="tips">
        <div className={`suggested-price ${color}`}>{price?.toLocaleString('en-US')}</div>
        <Space>
          <span className={`rate ${color}`}>{dValue > 0 ? '+' : ''}{dValue.toLocaleString('en-US')}</span>
          <span className={`rate ${color}`}>{dValue > 0 ? '+' : ''}{rate}{rate === '--' ? "" : "%"}</span>
        </Space><br />
        <table>
          <tbody>
          <tr>
            <td><b>Sug</b> {data?.SuggestedPrice?.toLocaleString('en-US') || '--'}</td>
            <td><b>Avg</b> {data?.Avg?.toLocaleString('en-US') || '--'}</td>
          </tr>
          <tr>
            <td><b>Min</b> {data?.Min?.toLocaleString('en-US') || '--'}</td>
            <td><b>Max</b> {data?.Max?.toLocaleString('en-US') || '--'}</td>
          </tr>
          <tr>
            <td><b>Entry</b> {data?.EntryCount?.toLocaleString('en-US') || '--'} </td>
            <td><b>Count</b> {data?.AmountCount?.toLocaleString('en-US') || '--'}</td>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <Popover
      content={renderTips()}
      trigger="hover"
      open={open}
      onOpenChange={handleOpenChange}
    >
      {props.children}
      {/*<Button shape="circle" icon={<SearchOutlined />} size="small" />*/}
    </Popover>
  )
}
