const Item = [
  {
    label: 'WEAPON', code: 0, children: [
      {
        label: 'One Hand', code: 0, children: [
          { label: 'Axe', code: 0, },
          { label: 'Dagger', code: 3, },
          { label: 'Mace', code: 1, },
          { label: 'Sword', code: 2, },
        ]
      },
      {
        label: 'Two Hand', code: 1, children: [
          { label: 'Axe', code: 4, },
          { label: 'Bow', code: 7, },
          { label: 'Flame Staff', code: 9, },
          { label: 'Frost Staff', code: 10, },
          { label: 'Healing Staff', code: 8, },
          { label: 'Lightning Staff', code: 11, },
          { label: 'Mace', code: 6, },
          { label: 'Sword', code: 5, },
        ]
      },
    ]
  },
  {
    label: 'APPAREL', code: 1, children: [
      {
        label: 'Accessory', code: 6, children: [
          { label: 'Appearance', code: 33, },
          { label: 'Neck', code: 34, },
          { label: 'Ring', code: 35, },
        ]
      },
      {
        label: 'Light Armor', code: 2, children: [
          { label: 'Chest', code: 12, },
          { label: 'Feet', code: 13, },
          { label: 'Hand', code: 14, },
          { label: 'Head', code: 15, },
          { label: 'Legs', code: 16, },
          { label: 'Shoulders', code: 17, },
          { label: 'Waist', code: 18, },
        ]
      },
      {
        label: 'Medium Armor', code: 3, children: [
          { label: 'Chest', code: 19, },
          { label: 'Feet', code: 20, },
          { label: 'Hand', code: 21, },
          { label: 'Head', code: 22, },
          { label: 'Legs', code: 23, },
          { label: 'Shoulders', code: 24, },
          { label: 'Waist', code: 25, },
        ]
      },
      {
        label: 'Heavy Armor', code: 4, children: [
          { label: 'Chest', code: 26, },
          { label: 'Feet', code: 27, },
          { label: 'Hand', code: 28, },
          { label: 'Head', code: 29, },
          { label: 'Legs', code: 30, },
          { label: 'Shoulders', code: 31, },
          { label: 'Waist', code: 32, },
        ]
      },
      { label: 'Shield', code: 5, }
    ]
  },
  {
    label: 'SOUL GEM GLYPHS', code: 2, children: [
      { label: 'Armor Glyph', code: 8, },
      { label: 'Jewelry Glyph', code: 10, },
      { label: 'Soul Gem', code: 7, },
      { label: 'Weapon Glyph', code: 9, },
    ]
  },
  {
    label: 'CRAFTING', code: 3, children: [
      {
        label: 'Alchemy', code: 11, children: [
          { label: 'Poison Base', code: 52, },
          { label: 'Potion Base', code: 36, },
          { label: 'Reagent', code: 37, },
        ]
      },
      { label: 'Armor Trait', code: 19, },
      {
        label: 'Black Smiting', code: 12, children: [
          { label: 'Material', code: 39, },
          { label: 'Raw Material', code: 38, },
          { label: 'Temper', code: 40, },
        ]
      },
      {
        label: 'Clothing', code: 13, children: [
          { label: 'Material', code: 42, },
          { label: 'Raw Material', code: 41, },
          { label: 'Tannin', code: 43, },
        ]
      },
      {
        label: 'Enchanting', code: 14, children: [
          { label: 'Aspect Runestone', code: 44, },
          { label: 'Essence Runestone', code: 45, },
          { label: 'Potency Runestone', code: 46, },
        ]
      },
      {
        label: 'Jewelry Crafting', code: 41, children: [
          { label: 'Material', code: 54, },
          { label: 'Raw Material', code: 53, },
          { label: 'Plating', code: 55, },
        ]
      },
      { label: 'Jewelry Trait', code: 42, },
      { label: 'Master Writ', code: 39, },
      { label: 'Motif', code: 17, },
      {
        label: 'Provisioning', code: 15, children: [
          { label: 'Ingredient', code: 47, },
          { label: 'Recipe', code: 48, },
        ]
      },
      { label: 'Raw Trait', code: 43, },
      { label: 'Style Material', code: 18, },
      { label: 'Style Raw Material', code: 30, },
      { label: 'Weapon Trait', code: 20, },
      {
        label: 'Woodworking', code: 16, children: [
          { label: 'Material', code: 50, },
          { label: 'Raw Material', code: 49, },
          { label: 'Resin', code: 51, },
        ]
      },
    ]
  },
  {
    label: 'FOOD POTIONS', code: 4, children: [
      { label: 'Drink', code: 22, },
      { label: 'Food', code: 21, },
      { label: 'Poison', code: 32, },
      { label: 'Potion', code: 23, },
    ]
  },
  {
    label: 'OTHER', code: 5, children: [
      { label: 'Bait', code: 24, },
      { label: 'Container', code: 28, },
      { label: 'Fish', code: 29, },
      { label: 'Misc', code: 31, },
      { label: 'Seige', code: 26, },
      { label: 'Tool', code: 25, },
      { label: 'Trophy', code: 27, },
    ]
  },
  {
    label: 'Furnishing', code: 6, children: [
      { label: 'Crafting Station', code: 33, },
      { label: 'Light', code: 34, },
      { label: 'Material', code: 40, },
      { label: 'Ornamental', code: 35, },
      { label: 'Recipe', code: 38, },
      { label: 'Seating', code: 36, },
      { label: 'Target Dummy', code: 37, },
    ]
  },
]

const Traits = [
  { label: "Any Trait", value: -1 },
  { label: "Aggressive", value: 26 },
  { label: "Arcane", value: 17 },
  { label: "Augmented", value: 27 },
  { label: "Bloodthirsty", value: 21 },
  { label: "Bolstered", value: 28 },
  { label: "Charged", value: 1 },
  { label: "Decisive", value: 7 },
  { label: "Defending", value: 4 },
  { label: "Divines", value: 13 },
  { label: "Focused", value: 29 },
  { label: "Harmony", value: 22 },
  { label: "Healthy", value: 18 },
  { label: "Impenetrable", value: 9 },
  { label: "Infused", value: 3 },
  { label: "Intricate", value: 15 },
  { label: "Invigorating", value: 12 },
  { label: "Nirnhoned", value: 14 },
  { label: "Ornate", value: 16 },
  { label: "Powered", value: 0 },
  { label: "Precise", value: 2 },
  { label: "Prolific", value: 30 },
  { label: "Protective", value: 23 },
  { label: "Quickened", value: 31 },
  { label: "Reinforced", value: 10 },
  { label: "Robust", value: 19 },
  { label: "Sharpened", value: 6 },
  { label: "Shattering", value: 32 },
  { label: "Soothing", value: 33 },
  { label: "Special", value: 20 },
  { label: "Sturdy", value: 8 },
  { label: "Swift", value: 24 },
  { label: "Training", value: 5 },
  { label: "Triune", value: 25 },
  { label: "Vigorous", value: 34 },
  { label: "Well Fitted", value: 11 },
]




const Quality = [
  { label: "Any", value: -1 },
  { label: "Normal", value: 0 },
  { label: "Fine", value: 1 },
  { label: "Superior", value: 2 },
  { label: "Epic", value: 3 },
  { label: "Legendary", value: 4 },
]
const Location =
  {
    [0]: "Belkarth",
    [1]: "BelkarthOutlawRefuge",
    [2]: "HollowCity",
    [3]: "HajUxith",
    [4]: "CourtOfContempt",
    [5]: "Rawlkha",
    [6]: "RawlkhaOutlawRefuge",
    [7]: "Vinedusk",
    [8]: "Dune",
    [9]: "Baandari",
    [10]: "Drabul",
    [11]: "Valeguard",
    [12]: "VelynHarborOutlawRefuge",
    [13]: "Marbruk",
    [14]: "MarbrukOutlawRefuge",
    [15]: "VerrantMorass",
    [16]: "Greenheart",
    [17]: "EldenRoot",
    [18]: "EldenRootOutlawRefuge",
    [19]: "Cormount",
    [20]: "Southpoint",
    [21]: "Skywatch",
    [22]: "Firsthold",
    [23]: "VulkhelGuard",
    [24]: "VulkhelGuardOutlawRefuge",
    [25]: "Mistral",
    [26]: "Evermore",
    [27]: "EvermoreOutlawRefuge",
    [28]: "BangkoraiPass",
    [29]: "HallinsStand",
    [30]: "Sentinel",
    [31]: "SentinelOutlawRefuge",
    [32]: "MorwhasBounty",
    [33]: "Bergama",
    [34]: "Shornhelm",
    [35]: "ShornhelmOutlawRefuge",
    [36]: "HoarfrostDowns",
    [37]: "Oldgate",
    [38]: "Wayrest",
    [39]: "WayrestOutlawRefuge",
    [40]: "FirebrandKeep",
    [41]: "KoeglinVillage",
    [42]: "Daggerfall",
    [43]: "DaggerfallOutlawRefuge",
    [44]: "LionGuardRedoubt",
    [45]: "WyrdTree",
    [46]: "Stonetooth",
    [47]: "PortHunding",
    [48]: "Riften",
    [49]: "RiftenOutlawRefuge",
    [50]: "Nimalten",
    [51]: "FallowstoneHall",
    [52]: "Windhelm",
    [53]: "WindhelmOutlawRefuge",
    [54]: "VoljarMeadery",
    [55]: "FortAmol",
    [56]: "Stormhold",
    [57]: "StormholdOutlawRefuge",
    [58]: "VenomousFens",
    [59]: "Hissmir",
    [60]: "Mournhold",
    [61]: "MournholdOutlawRefuge",
    [62]: "TalDeicGrounds",
    [63]: "MuthGnaarHills",
    [64]: "Ebonheart",
    [65]: "Kragenmoor",
    [66]: "DavonsWatch",
    [67]: "DavonsWatchOutlawRefuge",
    [68]: "Dhalmora",
    [69]: "Bleakrock",
    [70]: "Orsinium",
    [71]: "OrsiniumOutlawRefuge",
    [72]: "MorkulStronghold",
    [73]: "ThievesDen",
    [74]: "AbahsLanding",
    [75]: "Anvil",
    [76]: "Kvatch",
    [77]: "AnvilOutlawRefuge",
    [78]: "VivecCity",
    [79]: "VivecCityOutlawRefuge",
    [80]: "SadrithMora",
    [81]: "Balmora",
    [82]: "BrassFortress",
    [83]: "BrassFortressOutlawRefuge",
    [84]: "Lillandril",
    [85]: "Shimmerene",
    [86]: "Alinor",
    [87]: "AlinorOutlawRefuge",
    [88]: "Lilmoth",
    [89]: "LilmothOutlawRefuge",
    [90]: "Rimmen",
    [91]: "RimmenOutlawRefuge",
    [92]: "Senchal",
    [93]: "SenchalOutlawRefuge",
    [94]: "Solitude",
    [95]: "SolitudeOutlawRefuge",
    [96]: "Markarth",
    [97]: "MarkarthOutlawRefuge",
    [98]: "Leyawiin",
    [99]: "LeyawiinOutlawRefuge",
    [100]: "Fargrave",
    [101]: "FargraveOutlawRefuge",
    [102]: "GonfalonBay",
    [103]: "GonfalonBayOutlawRefuge",
    [104]: "Vastyr",
    [105]: "VastyrOutlawRefuge"
  }

const LocationString = {
  AbahsLanding: "Hew's Bane: Abah's Landing",
  Alinor: "Summerset: Alinor",
  AlinorOutlawRefuge: "Summerset: Alinor's Outlaw Refuge",
  Anvil: "The Gold Coast: Anvil",
  AnvilOutlawRefuge: "The Gold Coast: Anvil's Outlaw Refuge",
  Baandari: "Malabal Tor: Baandari Tradepost",
  Balmora: "Vvardenfell: Balmora",
  BangkoraiPass: "Bangkorai: Bangkorai Pass",
  Belkarth: "Craglorn: Belkarth",
  BelkarthOutlawRefuge: "Craglorn: Belkarth's Outlaw Refuge",
  Bergama: "Alik'r Desert: Bergama",
  Bleakrock: "Bleakrock Isle: Bleakrock",
  BrassFortress: "Clockwork City: Brass Fortress",
  BrassFortressOutlawRefuge: "Clockwork City: Brass Fortress's Outlaw Refuge",
  Cormount: "Grahtwood: Cormount",
  CourtOfContempt: "Coldharbour: Court of Contempt",
  Daggerfall: "Glenumbra: Daggerfall",
  DaggerfallOutlawRefuge: "Glenumbra: Daggerfall's Outlaw Refuge",
  DavonsWatch: "Stonefalls: Davon's Watch",
  DavonsWatchOutlawRefuge: "Stonefalls: Davon's Watch's Outlaw Refuge",
  Dhalmora: "Bal Foyen: Dhalmora",
  Drabul: "Malabal Tor: Dra'bul",
  Dune: "Reaper's March: Dune",
  Ebonheart: "Stonefalls: Ebonheart",
  EldenRoot: "Grahtwood:  Elden Root",
  EldenRootOutlawRefuge: "Grahtwood: Elden Root's Outlaw Refuge",
  Evermore: "Bangkorai: Evermore",
  EvermoreOutlawRefuge: "Bangkorai: Evermore's Outlaw Refuge",
  FallowstoneHall: "The Rift: Fallowstone Hall",
  FirebrandKeep: "Stormhaven: Firebrand Keep",
  Firsthold: "Auridon: Firsthold",
  FortAmol: "Eastmarch: Fort Amol",
  Greenheart: "Greenshade: Greenheart",
  HajUxith: "Coldharbour: Haj Uxith",
  HallinsStand: "Bangkorai: Hallin's Stand",
  Hissmir: "Shadowfen: Hissmir",
  HoarfrostDowns: "Rivenspire: Hoarfrost Downs",
  HollowCity: "Coldharbour: Hollow City",
  KoeglinVillage: "Stormhaven: Koeglin Village",
  Kragenmoor: "Stonefalls: Kragenmoor",
  Kvatch: "The Gold Coast: Kvatch",
  Lillandril: "Summerset: Lillandril",
  Lilmoth: "Murkmire: Lilmoth",
  LilmothOutlawRefuge: "Murkmire: Lilmoth's Outlaw Refuge",
  LionGuardRedoubt: "Glenumbra: Lion Guard Redoubt",
  Marbruk: "Greenshade: Marbruk",
  MarbrukOutlawRefuge: "Greenshade: Marbruk's Outlaw Refuge",
  Markarth: "The Reach: Markarth",
  MarkarthOutlawRefuge: "The Reach: Markarth's Outlaw Refuge",
  Mistral: "Khenarthi's Roost: Mistral",
  MorkulStronghold: "Wrothgar: Morkul Stronghold",
  MorwhasBounty: "Alik'r Desert: Morwha's Bounty",
  Mournhold: "Deshaan: Mournhold",
  MournholdOutlawRefuge: "Deshaan: Mournhold's Outlaw Refuge",
  MuthGnaarHills: "Deshaan: Muth Gnaar Hills",
  Nimalten: "The Rift: Nimalten",
  Oldgate: "Rivenspire: Oldgate",
  Orsinium: "Wrothgar: Orsinium",
  OrsiniumOutlawRefuge: "Wrothgar: Orsinium's Outlaw Refuge",
  PortHunding: "Stros M'Kai: Port Hunding",
  Rawlkha: "Reaper's March: Rawl'kha",
  RawlkhaOutlawRefuge: "Reaper's March: Rawl'kha's Outlaw Refuge",
  Riften: "The Rift: Riften",
  RiftenOutlawRefuge: "The Rift: Riften's Outlaw Refuge",
  Rimmen: "Northern Elsweyr: Rimmen",
  RimmenOutlawRefuge: "Northern Elsweyr: Rimmen's Outlaw Refuge",
  SadrithMora: "Vvardenfell: Sadrith Mora",
  Senchal: "Southern Elsweyr: Senchal",
  SenchalOutlawRefuge: "Southern Elsweyr: Senchal's Outlaw Refuge",
  Sentinel: "Alik'r Desert: Sentinel",
  SentinelOutlawRefuge: "Alik'r Desert: Sentinel's Outlaw Refuge",
  Shimmerene: "Summerset: Shimmerene",
  Shornhelm: "Rivenspire: Shornhelm",
  ShornhelmOutlawRefuge: "Rivenspire: Shornhelm's Outlaw Refuge",
  Skywatch: "Auridon: Skywatch",
  Solitude: "Western Skyrim: Solitude",
  SolitudeOutlawRefuge: "Western Skyrim: Solitude's Outlaw Refuge",
  Southpoint: "Grahtwood: Southpoint",
  Stonetooth: "Betnikh: Stonetooth",
  Stormhold: "Shadowfen: Stormhold",
  StormholdOutlawRefuge: "Shadowfen: Stormhold's Outlaw Refuge",
  TalDeicGrounds: "Deshaan: Tal'Deic Grounds",
  ThievesDen: "Hew's Bane: Thieves Den",
  Valeguard: "Malabal Tor: Valeguard",
  VelynHarborOutlawRefuge: "Malabal Tor: Velyn Harbor's Outlaw Refuge",
  VenomousFens: "Shadowfen: Venomous Fens",
  VerrantMorass: "Greenshade: Verrant Morass",
  Vinedusk: "Reaper's March: Vinedusk",
  VivecCity: "Vvardenfell: Vivec City",
  VivecCityOutlawRefuge: "Vvardenfell: Vivec City's Outlaw Refuge",
  VoljarMeadery: "Eastmarch: Voljar Meadery",
  VulkhelGuard: "Auridon: Vulkhel Guard",
  VulkhelGuardOutlawRefuge: "Auridon: Vulkhel Guard's Outlaw Refuge",
  Wayrest: "Stormhaven: Wayrest",
  WayrestOutlawRefuge: "Stormhaven: Wayrest's Outlaw Refuge",
  Windhelm: "Eastmarch: Windhelm",
  WindhelmOutlawRefuge: "Eastmarch: Windhelm's Outlaw Refuge",
  WyrdTree: "Glenumbra: Wyrd Tree",
  Leyawiin: "Blackwood: Leyawiin",
  LeyawiinOutlawRefuge: "Blackwood: Leyawiin's Outlaw Refuge",
  Fargrave: "Fargrave: Fargrave",
  FargraveOutlawRefuge: "Fargrave: Fargrave's Outlaw Refuge",
  GonfalonBay: "High Isle and Amenos: Gonfalon Bay",
  GonfalonBayOutlawRefuge: "High Isle and Amenos: Gonfalon Bay's Outlaw Refuge",
  Vastyr: "Galen and Y'ffelon: Vastyr",
  VastyrOutlawRefuge: "Galen and Y'ffelon: Vastyr's Outlaw Refuge",
}
export { Item, Traits, Location, Quality, LocationString }
