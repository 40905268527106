import { Traits } from "./data";

export function timeSince(date) {

  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}


export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const _APP_HOST = window.API_HOST;

const TraitsMap = Traits.reduce((prev, curr) => {
  prev[curr.value] = curr.label;
  return prev;
}, {})

export function getTraitName(id) {
  if (id === null) return '';
  if (id in TraitsMap) return TraitsMap[id];
  return ''
}

export function calcLevel(level) {
  const lv = parseInt(level);
  if (lv > 50) return `CP ${lv - 50}`;
  return `Level ${lv}`;
}

export function calc(a, b) {
  if (a === null || b === null || a === undefined || b === undefined) return { dValue: '--', rate: '--' }
  const dValue = Number.parseFloat(a) - Number.parseFloat(b);
  const rateValue = dValue / Number.parseFloat(b)
  const rate = Math.floor(rateValue * 10000) / 100
  return { dValue: Math.floor(dValue * 100) / 100, rate }
}
