import { Button, Space, Table, Tag } from "antd";
import React from "react";
import ListButton from "./list-button";
import { calc, timeSince } from "./lib";
import { RiseOutlined } from "@ant-design/icons";


export default function ListTable({ loading, dataList, handleTableChange, tableParams }) {

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, i) => {
        return (
          <div className={`item-entry item-quality-${i.quality}`}>
            {/*<img className="trade-item-icon" src={`https://us.tamrieltradecentre.com/Content/icons/${i.icon}`}*/}
            {/*     alt={text} />*/}
            <div className="info">
              <div className="item-name">{text}</div>
              <Space><span>{i.level}</span> <span>{i.trait}</span></Space>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text) => {
        if (!text) return null;
        const [ city = '', pos = '' ] = text.split(':');
        return (
          <>
            <Tag style={{ userSelect: 'all' }} color="blue"><b>{city}</b></Tag>
            <Tag style={{ userSelect: 'all' }} color="volcano">{pos}</Tag>
          </>
        );
      },
    },
    {
      title: 'Guild',
      dataIndex: 'guild',
      key: 'guild',
    },

    {
      title: 'Unit Price',
      dataIndex: 'Price',
      key: 'Price',
      sorter: true,
      showSorterTooltip: false,
      // align: 'right',
      sortDirections: [ 'ascend', 'descend' ],
      sortOrder: tableParams.orderKey === 'Price' ? tableParams.order : null,
      render: (price, i) => {
        const { SuggestedPrice } = i.diffPrice || {};
        const { dValue, rate } = calc(SuggestedPrice, price);
        let color = '';
        if (dValue > 0) color = 'raise';
        if (dValue < 0) color = 'fall';
        const prefix = dValue > 0 ? '+' : '';
        return <ListButton data={i.diffPrice} price={i.Price}>
          <div className={`price ${color}`}>
            {price?.toLocaleString('en-US')}
          </div>
          <div className={color}>
            {prefix}{dValue?.toLocaleString('en-US')} {prefix}{rate}{rate === '--' ? "" : "%"}
          </div>
        </ListButton>
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 80,
      render: (text) => `x${text?.toLocaleString('en-US')}`
    },
    {
      title: 'Total Price',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      render: (text) => `$${text.toLocaleString('en-US')}`
    },
    {
      title: 'Time',
      key: 'LastSeen',
      dataIndex: 'LastSeen',
      sorter: true,
      showSorterTooltip: false,
      sortDirections: [ 'ascend', 'descend' ],
      defaultSortOrder: 'ascend',
      sortOrder: tableParams.orderKey === 'LastSeen' ? tableParams.order : null,
      render: (date) => timeSince(date),
    },
    {
      title: 'History',
      key: 'action',
      align: 'center',
      render: (text, i) => {
        return <Space>
          <Button icon={<RiseOutlined />} shape="circle" size={"small"} onClick={() => {
            const newWindow = window.open('_blank');
            newWindow.location = `https://us.tamrieltradecentre.com/pc/Trade/PriceDetail/${i.linkID}`
          }} />
        </Space>
      }
    }
  ];

  return (
    <Table
      rowKey={(record) => record.key}
      loading={loading}
      columns={columns}
      dataSource={dataList}

      pagination={{
        current: tableParams.currentPage,
        total: tableParams.total,
        showSizeChanger: false,
        size: 'default',
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
      }}
      onChange={handleTableChange}
      size="small"
    />
  )
}
