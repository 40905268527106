import { AutoComplete, Button, InputNumber, Segmented, Select, Space } from "antd";
import { UpdateButton } from "./update-button";
import { Item, Quality, Traits } from "./data";
import React from "react";
import { _APP_HOST, debounce } from "./lib";


function renderSegmented(data, value, fn) {
  if (!data) return null;
  return (
    <>
      <span className="filter">
      {data.map((i, idx) => {
        return (
          <button
            key={idx}
            onClick={() => fn(idx)}
            className={`cls-select ${idx === value ? 'active' : ''}`}
          >
            {i.label}
          </button>
        )
      })}
      </span><br />
    </>
  )
}

export default function FilterBar({ onSearch, onReset }) {
  const [ firstIdx, setFirstIdx ] = React.useState(null);
  const [ secondIdx, setSecondIdx ] = React.useState(null);
  const [ thirdIdx, setThirdIdx ] = React.useState(null);
  const [ keyword, setKeyword ] = React.useState('');
  const [ trait, setTrait ] = React.useState(-1);
  const [ quality, setQuality ] = React.useState(-1);
  const [ options, setOptions ] = React.useState([]);
  const [ levelMin, setLevelMin ] = React.useState(null);
  const [ levelMax, setLevelMax ] = React.useState(null);
  const [ amountMin, setAmountMin ] = React.useState(null);
  const [ amountMax, setAmountMax ] = React.useState(null);
  const [ priceMin, setPriceMin ] = React.useState(null);
  const [ priceMax, setPriceMax ] = React.useState(null);

  function onBarReset() {
    setThirdIdx(null);
    setFirstIdx(null);
    setSecondIdx(null);
    setTrait(-1);
    setQuality(-1);
    setOptions([]);
    setKeyword('');
    setLevelMin(null);
    setLevelMax(null);
    setAmountMin(null);
    setAmountMax(null);
    setPriceMin(null);
    setPriceMax(null);
    onReset()
  }

  function onBarSearch(){
    const urlParams = [
      { param: 'ItemNamePattern', check: () => Boolean(keyword), value: () => keyword },
      { param: 'ItemCategory1ID', check: () => firstIdx !== null, value: () => Item[firstIdx].code },
      {
        param: 'ItemCategory2ID',
        check: () => secondIdx !== null,
        value: () => Item[firstIdx].children[secondIdx].code
      },
      {
        param: 'ItemCategory3ID',
        check: () => thirdIdx !== null,
        value: () => Item[firstIdx].children[secondIdx].children[thirdIdx].code
      },
      { param: 'ItemTraitID', check: () => trait !== -1, value: () => trait },
      { param: 'ItemQualityID', check: () => quality !== -1, value: () => quality },
      { param: 'LevelMin', check: () => levelMin !== null, value: () => levelMin },
      { param: 'LevelMax', check: () => levelMax !== null, value: () => levelMax },
      { param: 'AmountMin', check: () => amountMin !== null, value: () => amountMin },
      { param: 'AmountMax', check: () => amountMax !== null, value: () => amountMax },
      { param: 'PriceMin', check: () => priceMin !== null, value: () => priceMin },
      { param: 'PriceMax', check: () => priceMax !== null, value: () => priceMax },
    ];

    const reqObj = urlParams.reduce((prev, curr) => {
      if (curr.check()) prev[curr.param] = curr.value();
      return prev;
    }, {})

    console.log(reqObj,`<---- reqObj`);
    onSearch(reqObj);
  }

  function onFirstClick(idx) {
    setFirstIdx((v) => v === idx ? null : idx)
    setSecondIdx(null);
    setThirdIdx(null)
  }

  function onSecClick(idx) {
    setSecondIdx((v) => v === idx ? null : idx)
    setThirdIdx(null)
  }

  function onThirdClick(idx) {
    setThirdIdx((v) => v === idx ? null : idx)
  }

  function onSearchChange(v) {
    setKeyword(v);
  }

  const onSearchInput = debounce((v) => {
    if (v.length < 3) {
      setOptions([]);
      return;
    }

    fetch(`${_APP_HOST}/autocomplete?term=${v}`)
      .then(r => r.json())
      .then(r => {
        console.log(r, `<---- r`);
        const options = r.map(i => ({
          key: i.ItemID,
          label: i.label,
          value: i.value,
        }))
        setOptions(options);
      })
  })

  return (
    <div>
      <div className="entry">
        <Space>
          <AutoComplete
            options={options}
            style={{
              width: 300,
            }}
            allowClear
            backfill
            onChange={onSearchChange}
            onSearch={onSearchInput}
            value={keyword}
            placeholder="Please enter keyword"
          />
          <Button onClick={onBarSearch} type="primary">Search</Button>
          <Button onClick={onBarReset}>Reset</Button>
          <UpdateButton />
        </Space>
      </div>
      <div className="entry">
        <Space>
          Type:
          <div>
            {
              renderSegmented(Item, firstIdx, onFirstClick)
            }
            {
              Item[firstIdx] &&
              Item[firstIdx].children &&
              renderSegmented(Item[firstIdx].children, secondIdx, onSecClick)
            }
            {
              Item[firstIdx] &&
              Item[firstIdx].children &&
              Item[firstIdx].children[secondIdx] &&
              Item[firstIdx].children[secondIdx].children &&
              renderSegmented(Item[firstIdx].children[secondIdx].children, thirdIdx, onThirdClick)
            }
          </div>
        </Space>
      </div>
      <div className="entry">
        <Space>
          Trait:
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select Trait"
            optionFilterProp="children"
            value={trait}
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
            onChange={(v) => setTrait(v)}
            options={Traits}
          />
          Quality:
          <Segmented
            options={Quality.map(i => ({
              label: (<span className={`item-quality-${i.value}`}><i className="item-name">{i.label}</i></span>),
              value: i.value
            }))}
            value={quality}
            onChange={(v) => setQuality(v)}
          />
        </Space>
      </div>
      <div className="entry">
        <Space>
          Level:
          <InputNumber value={levelMin} onChange={(v) => setLevelMin(v)} />
          -
          <InputNumber value={levelMax} onChange={(v) => setLevelMax(v)} />
          Price:
          <InputNumber
            value={priceMin}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(v) => setPriceMin(v)}
            style={{ width: 200 }}
          /> -
          <InputNumber
            value={priceMax}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(v) => setPriceMax(v)}
            style={{ width: 200 }}
          />
          Amount:
          <InputNumber
            value={amountMin}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(v) => setAmountMin(v)}
          /> -
          <InputNumber
            value={amountMax}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(v) => setAmountMax(v)}
          />
        </Space>
      </div>
    </div>
  )
}
